import { getOwner } from '@ember/owner';
import { registerDestructor } from '@ember/destroyable';

function change({ when, onConfirm }, context) {
  return async function (transition) {
    if (transition.isAborted) return;
    if (transition.queryParamsOnly) return;

    if (when(transition)) {
      transition.abort();
      let discardChanges = getOwner(context).lookup('service:discard-changes');
      let isConfirmed = await discardChanges.ask();
      if (isConfirmed) {
        onConfirm();
        transition.retry();
      }
    }
  };
}

export function abortRouteChange() {
  let context = arguments[arguments.length - 1];
  let cb = change(...arguments);
  let router = getOwner(context).lookup('service:router');

  router.on('routeWillChange', cb);
  registerDestructor(context, () => router.off('routeWillChange', cb));
}
