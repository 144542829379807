import Service, { service } from '@ember/service';
import { task, dropTask, timeout } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';

import generateLegacyReportMutation from 'my-phorest/gql/mutations/generate-legacy-report.graphql';
import getReportUrlQuery from 'my-phorest/gql/queries/get-report-url.graphql';
import getReportStatusQuery from 'my-phorest/gql/queries/get-report-status.graphql';

import timeoutForEnv from 'my-phorest/utils/timeout-for-env';
import { asMoment } from 'my-phorest/utils/local-datetime-helpers';

const POLLING_MIN_INTERVAL_MS = 500;
const POLLING_MAX_INTERVAL_MS = 64000;

export default class LegacyReportsService extends Service {
  @queryManager apollo;
  @service branchTime;
  @service intl;
  @service notifications;

  @dropTask
  *generateReportTask({
    onReportGenerating,
    fileType,
    inputVariables,
    reportName,
  }) {
    onReportGenerating?.(true);
    const reportId = yield this.generateLegacyReportTask.perform({
      inputVariables,
      reportName,
    });

    let reportStatus = 'PENDING';
    let reportUrl = '';

    let delay = POLLING_MIN_INTERVAL_MS;
    while (['PENDING', 'PROCESSING'].includes(reportStatus)) {
      if (this.getReportStatusTask.performCount > 0) {
        yield timeout(timeoutForEnv(delay));
        if (
          this.getReportStatusTask.performCount > 5 &&
          delay < POLLING_MAX_INTERVAL_MS
        ) {
          delay *= 2;
        }
      }

      reportStatus = yield this.getReportStatusTask.perform(reportId);
    }

    if (reportStatus !== 'DONE') {
      this.notifications.failure(
        this.intl.t('report-generation.failed-message')
      );
      onReportGenerating?.(false);
      return;
    }

    reportUrl = yield this.getReportTask.perform(reportId, fileType);

    onReportGenerating?.(false);

    return { reportId, reportUrl };
  }

  @dropTask
  *generateLegacyReportTask({ inputVariables, reportName }) {
    let input = { ...inputVariables };

    if (reportName) {
      const date = asMoment(this.branchTime.now).format('DD-MMM-YYYY HH:mm');
      input.name = `${reportName} ${date}`;
    }
    if (!Array.isArray(input.rules)) input.rules = [];
    if (!Array.isArray(input.identityRules)) input.identityRules = [];

    let generateLegacyReport = yield this.apollo.mutate(
      {
        mutation: generateLegacyReportMutation,
        variables: {
          input,
        },
      },
      'generateLegacyReport'
    );

    return generateLegacyReport.reportId;
  }

  @task
  *getReportTask(reportId, fileType = 'PDF') {
    let report = yield this.apollo.query(
      {
        query: getReportUrlQuery,
        variables: {
          reportId,
          fileType: fileType ?? 'PDF',
        },
      },
      'getReportUrl'
    );

    return report.reportUrl;
  }

  @dropTask
  *getReportStatusTask(reportId) {
    let response = yield this.apollo.query(
      {
        query: getReportStatusQuery,
        variables: {
          reportId,
        },
        fetchPolicy: 'network-only',
      },
      'getReportStatus'
    );

    return response.state;
  }
}
