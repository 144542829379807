import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import sessionTillBalanceQuery from 'my-phorest/gql/queries/session-till-balance.graphql';
import countTillSessionMutation from 'my-phorest/gql/mutations/count-till-session.graphql';
import {
  addMoney,
  subtractMoney,
  sumMoneyCollection,
} from 'my-phorest/utils/currency';
import { action } from '@ember/object';

export default class CashUpSlideOverService extends Service {
  @queryManager apollo;
  @service session;

  @tracked tillSession;
  @tracked paymentMethodBalances;
  @tracked phorestPayTotals;
  @tracked notes;
  @tracked endCashAmount;

  @tracked isOpen = false;

  async openWithTillSession(tillSession) {
    this._resetState();
    this.tillSession = tillSession;
    this.isOpen = true;
    const { balances, integratedPaymentTotals } =
      await this.loadBankedPaymentMethodBalances();
    this.paymentMethodBalances = balances;
    this.phorestPayTotals = integratedPaymentTotals;
    this.notes = this.tillSession.notes;
    this.endCashAmount = this.tillSession.endCashAmount ?? '0.00';
  }

  close() {
    this.isOpen = false;
  }

  async completeCashUp() {
    const result = await this.countTillSession();
    this.close();
    return result;
  }

  get cashActualAmount() {
    let cashPaymentMethod = this.paymentMethodBalances.find(
      (pmb) => pmb.paymentMethod.paymentType === 'CASH'
    );

    return cashPaymentMethod?.actualAmount ?? 0;
  }

  @action
  setCashAmount(amount) {
    let cashBalance = this.paymentMethodBalances.find(
      (pmb) => pmb.paymentMethod.paymentType === 'CASH'
    );

    const newCashBalance = {
      ...cashBalance,
      actualAmount: amount,
    };
    this._replacePaymentMethodBalance(newCashBalance);
  }

  get totalActualAmount() {
    return sumMoneyCollection(this.paymentMethodBalances, 'actualAmount');
  }

  get totalExpectedAmount() {
    return sumMoneyCollection(this.paymentMethodBalances, 'expectedAmount');
  }

  get actualMinusExpectedAmount() {
    return subtractMoney(this.totalActualAmount, this.totalExpectedAmount);
  }

  get cashToBank() {
    return subtractMoney(this.cashActualAmount, this.endCashAmount);
  }

  get totalPhorestPayAmount() {
    return sumMoneyCollection(this.phorestPayTotals, 'amount');
  }

  get grandTotal() {
    return addMoney(this.totalExpectedAmount, this.totalPhorestPayAmount);
  }

  paymentMethodClicked(paymentMethodId) {
    const paymentMethodBalance =
      this._findPaymentMethodBalance(paymentMethodId);
    const newPaymentMethodBalance = {
      ...paymentMethodBalance,
      actualAmount: paymentMethodBalance.expectedAmount,
    };
    this._replacePaymentMethodBalance(newPaymentMethodBalance);
  }

  newActualValueForPaymentMethod(paymentMethodId, value) {
    const paymentMethodBalance =
      this._findPaymentMethodBalance(paymentMethodId);
    const newPaymentMethodBalance = {
      ...paymentMethodBalance,
      actualAmount: value,
    };
    this._replacePaymentMethodBalance(newPaymentMethodBalance);
  }

  _replacePaymentMethodBalance(newPaymentMethodBalance) {
    this.paymentMethodBalances = this.paymentMethodBalances.map((pmb) => {
      return pmb.paymentMethod.id === newPaymentMethodBalance.paymentMethod.id
        ? newPaymentMethodBalance
        : pmb;
    });

    if (newPaymentMethodBalance.paymentMethod.paymentType === 'CASH') {
      this.newEndCashAmount(newPaymentMethodBalance.actualAmount);
    }
  }

  _findPaymentMethodBalance(paymentMethodId) {
    return this.paymentMethodBalances.find(
      (pmb) => pmb.paymentMethod.id === paymentMethodId
    );
  }

  newEndCashAmount(endCashAmount) {
    if (Number(endCashAmount) > Number(this.cashActualAmount)) {
      this.endCashAmount = this.cashActualAmount;
    } else if (Number(endCashAmount) < 0) {
      this.endCashAmount = '0.00';
    } else {
      this.endCashAmount = endCashAmount;
    }
  }

  newCashToBank(cashToBank) {
    const newEndCashAmount = subtractMoney(this.cashActualAmount, cashToBank);
    this.newEndCashAmount(newEndCashAmount);
  }

  _resetState() {
    this.paymentMethodBalances = null;
    this.notes = null;
    this.endCashAmount = null;
  }

  async loadBankedPaymentMethodBalances() {
    const { tillId, id: tillSessionId } = this.tillSession;
    const sessionTillBalanceResponse = await this.apollo.query({
      query: sessionTillBalanceQuery,
      variables: {
        tillId,
        tillSessionId,
      },
      fetchPolicy: 'network-only',
    });

    const { balances, integratedPaymentTotals } =
      sessionTillBalanceResponse.sessionTillBalance;
    return {
      balances: balances
        .filter(
          (paymentMethodBalance) =>
            !(
              paymentMethodBalance.paymentMethod.archived &&
              Number(paymentMethodBalance.expectedAmount) === 0
            ) &&
            ![
              'ACCOUNT',
              'APPOINTMENT_DEPOSIT',
              'VOUCHER',
              'MEMBERSHIP',
            ].includes(paymentMethodBalance.paymentMethod.paymentType)
        )
        .map((item) => ({
          ...item,
          actualAmount: item.actualAmount ?? '0.00',
        })),
      integratedPaymentTotals,
    };
  }

  async countTillSession() {
    const { tillId, id: tillSessionId } = this.tillSession;
    return await this.apollo.mutate(
      {
        mutation: countTillSessionMutation,
        variables: {
          tillId,
          tillSessionId,
          countingStaffId: this.session.currentStaffMemberId,
          notes: this.notes,
          endCashAmount: this.endCashAmount,
          counts: this.paymentMethodBalances.map((pmb) => {
            return {
              paymentType: pmb.paymentMethod.paymentType,
              paymentMethodId: pmb.paymentMethod.id,
              amount: pmb.actualAmount || 0.0,
            };
          }),
        },
      },
      'countTillSession'
    );
  }
}
