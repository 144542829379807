import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { today } from 'my-phorest/utils/local-date-helpers';
import { task, waitForProperty } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import clientQuery from 'my-phorest/components/slide-over/appointment/-client-loader/client.graphql';

import { variation } from 'ember-launch-darkly';

export const LOCAL_STORAGE_KEY = 'CREATE-TREAT-CARD-ASKED';

export default class CreateTreatCardModalService extends Service {
  @service session;
  @service('browser/local-storage') localStorage;

  @queryManager apollo;

  @tracked isOpen = false;

  @action
  close() {
    this.isOpen = false;
  }

  @action
  open() {
    this.isOpen = true;
  }

  @task
  *askAndWaitForModalActionTask() {
    this.open();
    yield waitForProperty(this, 'isOpen', false);
  }

  @task
  *maybeAskForCreatingTreatCardTask(clientId, options = {}) {
    if (!variation('release-create-treat-card-prompt')) return false;

    let client = yield this.fetchClientTask.perform(clientId);
    if (client.isWalkIn) return false;

    let hasSerial = !!client.treatCard?.serial;

    if (!hasSerial) {
      let shouldOpen = this.#shouldOpenModal(clientId, options);
      if (shouldOpen) {
        yield this.askAndWaitForModalActionTask.perform();
      }
    }
  }

  #shouldOpenModal(clientId, options) {
    if (this.wasClientAskedToday(clientId)) return false;

    if (
      options?.action === 'pay' &&
      this.session.branch.loyaltySettings.promptNonMembersOnPay
    ) {
      return true;
    } else if (
      options?.action === 'check-in' &&
      this.session.branch.loyaltySettings.promptNonMembersOnCheckIn
    ) {
      return true;
    }

    return false;
  }

  wasClientAskedToday(clientId) {
    let map = this.#getClientsAsked();
    if (map) {
      return map.date === today() && map.clientIds.includes(clientId);
    }
    return false;
  }

  addClientIdToClientsAsked(clientId) {
    let list = this.#getClientsAsked();
    let clientIds = new Set(list.clientIds);
    clientIds.add(clientId);
    list.clientIds = [...clientIds];
    this.#saveList(list);

    return this.#getClientsAsked();
  }

  get key() {
    return `${this.session.branchId}:${LOCAL_STORAGE_KEY}`;
  }

  @task
  *fetchClientTask(clientId) {
    return yield this.apollo.query(
      {
        query: clientQuery,
        variables: {
          id: clientId,
        },
      },
      'client'
    );
  }

  get client() {
    return this.fetchClientTask.lastSuccessful?.value;
  }

  #getClientsAsked() {
    let list = this.#readList();
    if (list) {
      list = JSON.parse(list);
      if (list.date === today()) {
        return list;
      } else {
        this.#createEmptyTodayList();
      }
    } else {
      this.#createEmptyTodayList();
    }
    return this.#getClientsAsked();
  }

  #saveList(list) {
    return this.localStorage.setItem(this.key, JSON.stringify(list));
  }

  #readList() {
    return this.localStorage.getItem(this.key);
  }

  #createEmptyTodayList() {
    let list = {
      date: today(),
      clientIds: [],
    };
    this.#saveList(list);
  }
}
