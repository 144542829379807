import config from 'my-phorest/config/environment';
import fetch from 'fetch';
import jwtDecode from 'jwt-decode';
import { isEmpty } from '@ember/utils';

export function jwtDestructure(token) {
  try {
    const { exp, client_type, user_id, allowed, iss, sub } = jwtDecode(token);
    const [first] = allowed;
    const { business_id, silo } = first;
    return { exp, client_type, user_id, business_id, silo, iss, sub };
  } catch (error) {
    return {
      exp: null,
      user_id: null,
      client_type: null,
      business_id: null,
      silo: null,
      iss: null,
      sub: null,
    };
  }
}

function tokenExpirationTimestamp(token) {
  const { exp: numericDate } = jwtDecode(token);
  return new Date(numericDate * 1000).valueOf();
}

export function secondsUntilExpiry(token) {
  const now = new Date().valueOf();
  const millisecondsUntilExpiry = tokenExpirationTimestamp(token) - now;
  return Math.floor(millisecondsUntilExpiry / 1000);
}

async function fetchTokens(payload) {
  let response = await fetch(`${config.APP.authServiceURL}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  let data = await response.json();
  if (!response.ok) {
    throw response;
  }
  return { accessToken: data.access_token, refreshToken: data.refresh_token };
}

export async function getAccessToken(username, password) {
  return fetchTokens({
    grant_type: 'basic',
    client_type: 'user',
    username,
    password,
  });
}

export async function refreshAccessToken(refreshToken) {
  const maxRetries = 3;
  const retryDelayStep = 1000;
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      return await fetchTokens({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      });
    } catch (error) {
      attempts++;

      /* Note on `!error.status`:
          It should cover network issue ("net::ERR_EMPTY_RESPONSE") and when `error.status` equals `0`
       */
      const isErrorWorthRetrying = !error.status || error.status >= 500;

      if (attempts <= maxRetries && isErrorWorthRetrying) {
        await new Promise((resolve) =>
          setTimeout(resolve, attempts * retryDelayStep)
        );
      } else {
        throw error;
      }
    }
  }
}

export async function getTokens(username, password) {
  const pToken = tokenize(username, password);
  const { accessToken, refreshToken } = await getAccessToken(
    username,
    password
  );
  return { accessToken, refreshToken, pToken };
}

export function tokenize(username, password) {
  if (isEmpty(username) || isEmpty(password)) {
    return null;
  }

  return window.btoa(`user/${username}:${password}`);
}
