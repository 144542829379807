import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { identify } from 'ember-launch-darkly';
import config from 'my-phorest/config/environment';
import { appVersionString } from 'my-phorest/utils/app-version';
import { assert } from '@ember/debug';
import { variation } from 'ember-launch-darkly';
import * as calendarCache from 'my-phorest/utils/calendar-cache';
import * as Sentry from '@sentry/browser';

export default class AccountsAccountRoute extends Route {
  @service apollo;
  @service apolloSubscriptions;
  @service autoLogout;
  @service canny;
  @service electronApp;
  @service eventPropagator;
  @service intl;
  @service pendo;
  @service router;
  @service session;
  @service swingBridge;
  @service zendesk;

  beforeModel({ to }) {
    let routeInfo = to.find((item) => item?.name === 'accounts.account');
    let accountId = routeInfo?.params.account_id;
    assert("Couldn't find account_id parameter", accountId);
    let { branchesList } = this.session;

    let branch = branchesList.find((b) => b.accountId === parseInt(accountId));
    if (!branch) {
      this.router.transitionTo('accounts.index');
    }
  }

  async model({ account_id }) {
    this.session.rememberLastAccessedAccountId(account_id);
    this.session.currentAccountId = account_id;
    const { cache } = this.apollo.client;
    calendarCache.invalidate(cache);

    this.apolloSubscriptions.unsubscribe('calendarChange');
    this.apolloSubscriptions.subscribe('calendarChange', {
      branchId: this.session.branchId,
    });

    this.apolloSubscriptions.unsubscribe('rostersChange');
    this.apolloSubscriptions.subscribe('rostersChange', {
      branchId: this.session.branchId,
    });
  }

  async afterModel() {
    let canBeIdentified = await this._identifyWithLaunchDarkly();
    if (canBeIdentified) {
      await this._setupSentry();
      this.pendo.identify();
      this.zendesk.identify();
      this.canny.identify();
    } else {
      this.router.transitionTo('logout');
    }
  }

  activate() {
    if (this.swingBridge.isEmbeddedInSwing) {
      if (
        this.session.isTerminal &&
        this.session.terminalInfo?.logoutAfterMins
      ) {
        const logoutAfterMins = this.session.terminalInfo.logoutAfterMins;
        this.autoLogout.activate(logoutAfterMins);
      }
    } else if (variation('ops-branch-auto-logout')) {
      this._setBranchAutoLogout();
      this._branchChangeHandler = this._setBranchAutoLogout.bind(this);
      this.eventPropagator.subscribe('branchChange', this._branchChangeHandler);
    }
  }

  deactivate() {
    this.apolloSubscriptions.unsubscribe('calendarChange');
    this.apolloSubscriptions.unsubscribe('rostersChange');

    this.autoLogout.deactivate();
    this.eventPropagator.unsubscribe('branchChange', this._branchChangeHandler);
  }

  _setBranchAutoLogout() {
    this.autoLogout.deactivate();

    const logoutAfterMins = this.session.currentBranch?.autoLogoutMinutes;
    if (logoutAfterMins) {
      this.autoLogout.activate(logoutAfterMins);
    }
  }

  async _identifyWithLaunchDarkly() {
    let { branch, business, user } = this.session;
    if (!branch || !business || !user) {
      return false;
    }
    let { deployRevision } = config.APP;
    let { countryCode, lang } = business.locale;

    let locale = countryCode && lang ? `${lang}-${countryCode}` : 'unknown';

    let customProps = {
      accountId: branch.accountId,
      branchName: branch.shortName,
      businessName: business.name,
      businessLocale: locale,
      appVersion: deployRevision,
      browserOnly: true,
    };

    if (this.electronApp.isRunningInElectron) {
      customProps.electronVersion =
        await this.electronApp.getElectronAppVersion();
    }

    let props = {
      key: branch.accountId,
      name: branch.shortName,
      anonymous: false,
      custom: customProps,
    };

    try {
      await identify(props);
    } catch (err) {
      const message =
        '[LaunchDarkly] The `identify` request to LaunchDarkly failed. All flags have been switched to default values. LD might be experiencing an outage or the request has been blocked by the client.';

      console.warn(message);
      Sentry.withScope(function (scope) {
        scope.setLevel('warning');
        Sentry.captureException(new Error(message, { cause: err }));
      });
    }
    return true;
  }

  async _setupSentry() {
    let { branch, business, isGlobalUser, silo } = this.session;
    let appVersion = appVersionString(
      config.APP,
      this.swingBridge,
      this.electronApp
    );

    let user = {
      id: branch.accountId,
      branchName: branch.shortName,
      businessName: business.name,
      locale: this.intl.defaultLocale,
      silo,
    };

    let tags = {
      appVersion,
      'branch.accountId': branch.accountId,
      'branch.name': branch.name,
      'business.name': business.name,
      isGlobalUser,
      silo,
    };

    if (this.swingBridge.isEmbeddedInSwing && this.swingBridge.swingVersion) {
      tags.swingVersion = this.swingBridge.swingVersion;
    }

    if (this.electronApp.isRunningInElectron) {
      tags.electronVersion = await this.electronApp.getElectronAppVersion();
      this.electronApp.notifyDidSetupSentry({
        user,
        tags: {
          'branch.accountId': branch.accountId,
          'branch.name': branch.name,
          'business.name': business.name,
          isGlobalUser,
        },
      });
    }

    const scope = Sentry.getCurrentScope();
    scope.setTags(tags);
    scope.setUser(user);
  }
}
