import Service from '@ember/service';
import config from 'my-phorest/config/environment';
import { service } from '@ember/service';
import { restartableTask, timeout } from 'ember-concurrency';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { htmlSafe } from '@ember/template';

export default class EmbeddedAppService extends Service {
  @service('browser/window') window;
  @service apolloCache;
  @service electronApp;
  @service session;
  @service router;
  @service userIdleness;
  @service notifications;
  @service intl;

  $iframeElement = null;
  hasBooted = false;
  syncPath = true;
  _resolveWhenReady = null;
  currentURL = null;

  constructor() {
    super(...arguments);
    this.reset();
  }

  get origin() {
    return config.embeddedApp.origin;
  }

  get bootData() {
    const { accessToken, refreshToken, user, terminalId, globalInfo } =
      this.session;
    const { staffId } = user;

    return this.window.btoa(
      JSON.stringify({
        accessToken,
        refreshToken,
        staffId,
        terminalId,
        globalInfo,
      })
    );
  }

  attachIframe(iframe) {
    this.$iframeElement = iframe;
    this._makeReady();
  }

  reset() {
    this.syncPath = true;
    this.$iframeElement = null;
    this.hasBooted = false;
    this.currentURL = null;
    this.whenReady = new Promise((resolve) => {
      this._resolveWhenReady = resolve;
    });
  }

  visitPath(path) {
    if (path === this.currentURL) {
      return;
    }
    this._sendMessage({
      command: 'visitPath',
      value: `/${path}`,
    });
  }

  addMessageListener() {
    this.window.addEventListener('message', this.handleMessageFromEmbeddedApp);
  }

  removeMessageListener() {
    this.window.removeEventListener(
      'message',
      this.handleMessageFromEmbeddedApp
    );
  }

  @restartableTask
  *_synchronisePath(currentURL) {
    if (currentURL.includes('browser-boot')) {
      return;
    }

    yield timeout(50);
    this.currentURL = currentURL;
    this.router.replaceWith('accounts.account.embed.app.mirror', currentURL);
  }

  @dropTask
  *sendAvailablePrinters() {
    const printers = yield this.electronApp.getPrinters();
    this._sendMessage({
      command: 'availablePrinters',
      value: printers,
    });
  }

  @action
  async handleMessageFromEmbeddedApp(event) {
    let { command, currentURL, value } = event.data;

    const messageHandlers = {
      embeddedAppDidBoot: () => {
        this._onBoot();
      },
      pageDidChange: () => {
        if (this.syncPath) {
          this._synchronisePath.perform(currentURL);
        }
      },
      embeddedAppDidExit: () => {
        this.window.history.back();
        this.reset();
      },
      invalidateCache: async () => {
        return await this.apolloCache.handleEmbeddedAppSignalForCacheInvalidation(
          value
        );
      },
      userActivity: () => {
        this.userIdleness.trackActivityTask.perform();
      },
      getAvailablePrinters: () => {
        this.sendAvailablePrinters.perform();
      },
      loadReceiptPrinter: () => {
        this.electronApp.loadReceiptPrinter();
      },
      navigateToClientCard: () => {
        this.router.replaceWith('accounts.account.client.overview', value);
      },
      navigateTo: () => {
        this.router.transitionTo(`/a/${this.session.accountId}/${value}`);
      },
      showNotification: () => {
        const { level, options } = value;
        let { message } = value;

        if (typeof message === 'object') {
          message = htmlSafe(message.string);
        }

        if (['success', 'info'].includes(level)) {
          this.notifications.success(message, undefined, options);
        } else if (['danger', 'failure', 'warning'].includes(level)) {
          this.notifications.failure(
            this.intl.t('global.error-notification-title'),
            message,
            options
          );
        }
      },
    };

    if (typeof messageHandlers[command] === 'function') {
      return await messageHandlers[command]();
    }
  }

  _onBoot() {
    this.hasBooted = true;
    this._makeReady();

    if (this.electronApp.isRunningInElectron) {
      this._sendMessage({
        command: 'electronAvailability',
        value: this.electronApp.isRunningInElectron,
      });
    }
  }

  _makeReady() {
    if (this.hasBooted && this.$iframeElement) {
      this._resolveWhenReady();
    }
  }

  _sendMessage(message) {
    const { contentWindow } = this.$iframeElement;
    if (!contentWindow) {
      console.error('No iframe.contentWindow could be found.');
      return;
    }
    if (this.hasBooted) {
      contentWindow.postMessage(message, this.origin);
    } else {
      console.warn(
        'The embedded app has not booted yet, the message was not sent.',
        message
      );
    }
  }
}
